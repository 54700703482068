import React, { useEffect, useState } from 'react';
import api from '../../api';

const LinkedUserInput = props => {
  const [linkedUsers, setLinkedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.users.list(
      {},
      data => {
        setLinkedUsers(data.items.filter(a => (!props.onlyActive || a.id === parseInt(props.value) || a.active)));
        setIsLoading(false);
      },
      err => {
        setError(`${err}`);
        setIsLoading(false);
      },
    );
  }, [props.onlyActive, props.value]);

  return (
    <label
      className="input"
      style={props.style}
    >
      {props.label && (
        <span className="input_label">{props.label}</span>
      )}

      {props.tips && (
        <span className="input_tips">{props.tips}</span>
      )}

      {error !== null && (
        <span
          className="input_error"
          key={error}
        >
          {error}
        </span>
      )}

      {props.errors && props.errors.map(propsError => (
        <span
          className="input_error"
          key={propsError}
        >
          {propsError}
        </span>
      ))}

      <select
        className={`input_input${props.errors.length > 0 ? ' input_input--error' : ''}`}
        name={props.name}
        onChange={e => props.onChange({
          target: {
            name: e.target.name,
            value: e.target.value !== '' ? parseInt(e.target.value, 10) : null,
          },
        })}
        disabled={props.disabled}
        value={props.value ? props.value : ''}
      >
        <option value="">Aucun</option>

        {isLoading && (
          <option disabled value="">Chargement ...</option>
        )}

        {!isLoading && linkedUsers.length === 0 && (
          <option disabled value="">Aucun utilistaeur trouvé ...</option>
        )}

        {!isLoading && linkedUsers.length > 0 && linkedUsers.map(linkedUsers => (
          <option
            key={linkedUsers.id}
            value={linkedUsers.id}
          >
            {linkedUsers.firstname} {linkedUsers.lastname}
          </option>
        ))}
      </select>
    </label>
  );
};

LinkedUserInput.defaultProps = {
  errors: [],
  style: {},
  value: '',
  onlyActive: false,
  disabled: false,
};

export default LinkedUserInput;
