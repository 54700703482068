import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2'
import api from '../../api';
import ArticleContentEditor from '../article-content-editor';
import Button from '../button';
import CategoryInput from '../category-input';
import Checkbox from '../checkbox';
import Form from '../form';
import ImageInput from '../image-input';
import Input from '../input';
import Loader from '../loader';
import './article-form.css';
import SummaryEditor from '../summary-editor/summary-editor';
import TitleEditor from '../title-editor/title-editor';
import Select from '../select';
import AuthorInput from '../author-input';
import TagInput from '../tag-input';
import LinkedUserInput from '../linked-user-input';
import { useUser } from '../../context/user-context';

const defaultArticle = {
  titleHtml: '',
  summary: '',
  content: '',
  status: 'draft',
  redactionDate: '',
  publicationDate: '',
  categoryId: null,
  secondaryCategoryId: null,
  includedInNewsletter: false,
  positioning: 'none',
  liveTweetActive: false,
  liveTweetHashTag: '',
  tagId: null,
  authorId: null,
  mainImageId: null,
  linkedUserId: null,
  eans: '',
  keywords: '',
  metasFromFirstEan: false,
};

const defaultErrors = {
  title: [],
  titleHtml: [],
  summary: [],
  content: [],
  status: [],
  redactionDate: [],
  publicationDate: [],
  categoryId: [],
  secondaryCategoryId: [],
  includedInNewsletter: [],
  positioning: [],
  liveTweetActive: [],
  liveTweetHashTag: [],
  tagId: [],
  authorId: [],
  mainImageId: [],
  linkedUserId: [],
  eans: [],
  keywords: [],
  metasFromFirstEan: [],
};

const ArticleForm = props => {
  const user = useUser();

  const [article, setArticle] = useState(defaultArticle);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [infosFromEanIsLoading, setInfosFromEanIsLoading] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [michroniquesCategId, setMichroniquesCategId] = useState(null);

  useEffect(() => {
    api.categories.getByName('Michroniques', (categData) => {
      setMichroniquesCategId(categData.id);
    });
  }, []);

  const loadInfosFromEan = e => {
    e.preventDefault();
    setInfosFromEanIsLoading(true);

    setErrors({
      ...errors,
      eans: [],
    });

    api.eans.getData(article.eans, (data) => {

      if (!data) {
        setInfosFromEanIsLoading(false);
        return;
      }

      if (data.coverId) {
        setArticle({
          ...article,
          titleHtml: data.title ?? article.titleHtml,
          tagId: data.tagId ?? article.tagId,
          mainImageId: data.coverId,
          categoryId: michroniquesCategId,
          summary: '...',
          metasFromFirstEan: true,
        });

        setInfosFromEanIsLoading(false);
      } else if (data.coverUrl) {
        api.images.postUrl({url: data.coverUrl}, (imageData) => {
          setArticle({
            ...article,
            titleHtml: data.title ?? article.titleHtml,
            tagId: data.tagId ?? article.tagId,
            mainImageId: imageData.id,
            categoryId: michroniquesCategId,
            summary: '...',
            metasFromFirstEan: true,
          });

          setInfosFromEanIsLoading(false);
        }, (err) => {
          setErrors({
            ...errors,
            eans: ['Une erreur à eu lieu lors de la récupération de données'],
          });

          console.error(err);

          setInfosFromEanIsLoading(false);
        });
      } else {
        setArticle({
          ...article,
          titleHtml: data.title ?? article.titleHtml,
          tagId: data.tagId ?? article.tagId,
          categoryId: michroniquesCategId,
          summary: '...',
          metasFromFirstEan: true,
        });

        setInfosFromEanIsLoading(false);
      }
    });
  }

  const handleChange = e => {
    setArticle({
      ...article,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: [],
    });
  };

  const handleSubmit = e => {
    const apiMethod = props.id ? api.articles.put : api.articles.post;
    const apiParams = props.id ? { id: props.id, article } : { article };

    article.redactionDate = article.redactionDate ? moment(article.redactionDate, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DD HH:mm:ss') : '';
    article.publicationDate = article.redactionDate;

    e.preventDefault();
    setIsLoading(true);
    apiMethod(
      apiParams,
      (data) => {
        if (props.id) {
          setIsLoading(false);
          Swal.fire({
            text: 'Article modifié avec succès',
            showClass: {
              popup: 'swal2-noanimation',
              backdrop: 'swal2-noanimation',
            },
            hideClass: {
              popup: '',
              backdrop: '',
            },
          });
          setArticle({
            ...article,
            ...data,
            redactionDate: article.redactionDate ? moment(article.redactionDate).format('DD/MM/YYYY HH:mm') : '',
            publicationDate: article.publicationDate ? moment(article.publicationDate).format('DD/MM/YYYY HH:mm') : '',
          });
        } else {
          setRedirect(`/articles/${data.id}/modifier`);
          Swal.fire({
            text: 'Article créé avec succès',
            showClass: {
              popup: 'swal2-noanimation',
              backdrop: 'swal2-noanimation',
            },
            hideClass: {
              popup: '',
              backdrop: '',
            },
          });
        }
      },
      err => {
        if (err.response) {
          let newErrors = JSON.parse(JSON.stringify(defaultErrors));
          err.response.data.violations.map(error => {
            return newErrors[error.propertyPath].push(error.title);
          });
          setArticle({
            ...article,
            redactionDate: article.redactionDate ? moment(article.redactionDate).format('DD/MM/YYYY HH:mm') : '',
            publicationDate: article.publicationDate ? moment(article.publicationDate).format('DD/MM/YYYY HH:mm') : '',
          });
          setErrors(newErrors);
        } else {
          console.error(err);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    setRedirect('');
    if (props.id) {
      setIsLoading(true);
      api.articles.get(
        props.id,
        article => {
          let newArticle = {
            ...defaultArticle,
            ...article,
            redactionDate: article.redactionDate ? moment(article.redactionDate).format('DD/MM/YYYY HH:mm') : '',
            publicationDate: article.publicationDate ? moment(article.publicationDate).format('DD/MM/YYYY HH:mm') : '',
            categoryId: article.category ? article.category.id : null,
            secondaryCategoryId: article.secondaryCategory ? article.secondaryCategory.id : null,
            authorId: article.author ? article.author.id : null,
            tagId: article.tag ? article.tag.id : null,
            linkedUserId: article.linkedUser ? article.linkedUser.id : null,
            mainImageId: article.mainImage ? article.mainImage.id : null,
            eans: article.eans ? article.eans.split(',').map(k => k.trim()).filter(k => k.length > 0).join(', ') : null,
            keywords: article.keywords ? article.keywords.split(',').map(k => k.trim()).filter(k => k.length > 0).join(', ') : null,
          };

          delete newArticle.category;
          delete newArticle.legacyId;
          delete newArticle.legacyUrl;
          delete newArticle.secondaryCategory;
          delete newArticle.shortUrl;
          delete newArticle.slug;
          delete newArticle.author;
          delete newArticle.tag;
          delete newArticle.linkedUser;
          delete newArticle.mainImage;
          setArticle(newArticle);
          setIsLoading(false);
        },
      );
    } else {
      setArticle(defaultArticle);
    }
  }, [props.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="article-form">
      <Form
        onSubmit={handleSubmit}
      >
        <div className="article-form_form">
          <div className="article-form_column">

            <LinkedUserInput
                errors={errors.linkedUserId}
                label="Utilisateur lié"
                name="linkedUserId"
                onChange={handleChange}
                value={article.linkedUserId}
                onlyActive={true}
                disabled={!user || user.roles.indexOf('ROLE_ADMIN') === -1}
              />

            <Input
              errors={errors.keywords}
              label="Mots-clés"
              tips="Séparés par une virgule"
              name="keywords"
              onChange={handleChange}
              type="text"
              value={article.keywords || ''}
            />

            <Input
              errors={errors.eans}
              label="EANs"
              tips="Séparés par une virgule"
              name="eans"
              onChange={handleChange}
              type="text"
              value={article.eans || ''}
            />

            {article.eans && article.eans.match(/^\d+$/) &&
              <Button
                className="article-load-from-ean-button"
                label={infosFromEanIsLoading ? 'Chargement...' : 'Pré-remplir pour Michroniques'}
                theme="info"
                size="sm"
                type="button"
                icon={infosFromEanIsLoading ? 'hourglass_bottom' : ''}
                onClick={e => loadInfosFromEan(e)}
              />
            }

            <Checkbox
              errors={errors.metasFromFirstEan}
              label="Inclure les metadonnées du premier EAN"
              tips="Si présent"
              name="metasFromFirstEan"
              onChange={handleChange}
              style={{width: '100%', marginRight: '10px'}}
              value={article.metasFromFirstEan}
            />

            <div className="article-form_row">
              <CategoryInput
                errors={errors.categoryId}
                label="Catégorie"
                name="categoryId"
                onChange={handleChange}
                style={{width: '100%', marginRight: '10px'}}
                value={article.categoryId}
              />

              <CategoryInput
                errors={errors.secondaryCategoryId}
                label="Catégorie secondaire"
                name="secondaryCategoryId"
                onChange={handleChange}
                style={{width: '100%', marginLeft: '10px'}}
                value={article.secondaryCategoryId}
              />
            </div>

            <div className="article-form_row">
              <TagInput
                errors={errors.tagId}
                label="Option"
                name="tagId"
                onChange={handleChange}
                style={{width: '100%', marginRight: '10px'}}
                value={article.tagId}
              />

              <AuthorInput
                errors={errors.authorId}
                label="Auteur"
                name="authorId"
                onChange={handleChange}
                style={{width: '100%', marginLeft: '10px'}}
                value={article.authorId}
                onlyActive={true}
              />
            </div>

            <Input
              errors={errors.redactionDate}
              label="Date de publication"
              name="redactionDate"
              onChange={handleChange}
              style={{width: '100%', marginRight: '10px'}}
              value={article.redactionDate}
              tips="JJ/MM/AAAA HH:mm"
              type="text"
            />

            <div className="article-form_row">
              <Checkbox
                errors={errors.includedInNewsletter}
                label="Newsletter"
                name="includedInNewsletter"
                onChange={handleChange}
                style={{width: '100%', marginRight: '10px'}}
                value={article.includedInNewsletter}
              />

              <Select
                errors={errors.positioning}
                label="Affichage dans la catégorie"
                name="positioning"
                options={[
                  {
                    label: 'Normal',
                    value: 'none',
                  },
                  {
                    label: 'À la une',
                    value: 'main',
                  },
                  {
                    label: 'Une secondaire',
                    value: 'secondary',
                  },
                  {
                    label: 'Mis en avant',
                    value: 'highlighted',
                  },
                ]}
                onChange={handleChange}
                style={{width: '100%', marginLeft: '10px'}}
                value={article.positioning}
              />
            </div>

            <div className="article-form_row">
              <Checkbox
                errors={errors.liveTweetActive}
                label="Live Tweet actif"
                name="liveTweetActive"
                onChange={handleChange}
                style={{width: '100%', marginRight: '10px'}}
                value={article.liveTweetActive}
              />

              <Input
                errors={errors.liveTweetHashTag}
                label="#hashtag (sans le #)"
                name="liveTweetHashTag"
                onChange={handleChange}
                style={{width: '100%', marginLeft: '10px'}}
                type="text"
                value={article.liveTweetHashTag || ''}
              />
            </div>

            <div className="article-form_row">
              <Select
                errors={errors.status}
                label="Statut"
                name="status"
                options={[
                  {
                    label: 'En préparation',
                    value: 'draft',
                  },
                  {
                    label: 'En relecture',
                    value: 'review',
                  },
                  {
                    label: 'Publié',
                    value: 'published',
                  },
                  {
                    label: 'Archivé',
                    value: 'archived',
                  },
                ]}
                onChange={handleChange}
                value={article.status}
              />
            </div>
          </div>

          <div className="article-form_column">
            <TitleEditor
              errors={errors.titleHtml}
              label="Titre"
              name="titleHtml"
              onChange={handleChange}
              value={article.titleHtml}
            />

            <ImageInput
              errors={errors.mainImageId}
              label="Image Principale"
              name="mainImageId"
              onChange={handleChange}
              value={article.mainImageId}
            />
          </div>

          <div className="article-form_texts">
            {michroniquesCategId !== article.categoryId &&
              <SummaryEditor
                errors={errors.summary}
                label="Introduction"
                name="summary"
                onChange={handleChange}
                value={article.summary}
              />
            }

            <ArticleContentEditor
              errors={errors.content}
              label="Contenu"
              name="content"
              onChange={handleChange}
              value={article.content}
            />
          </div>
        </div>

        <Button
          className="article-form_submit-button"
          label={`${props.id ? 'Modifier' : 'Créer'} l'article`}
          theme="success"
          type="submit"
        />
      </Form>
    </div>
  );
};

ArticleForm.propTypes = {
  id: PropTypes.number,
};

export default ArticleForm;
