import React, { useEffect, useState } from 'react';
import api from '../../api';
import { NavLink } from 'react-router-dom';
import { useUser } from '../../context/user-context';
import './layout-nav.css';

const LayoutNavLink = ({ to, children }) => (
  <NavLink
    activeClassName="layout-nav_link--active"
    className="layout-nav_link"
    to={to}
  >
    {children}
  </NavLink>
);

const LayoutNav = () => {
  const user = useUser();
  const [countWatingComments, setCountWatingComments] = useState('...');

  useEffect(() => {
    api.comments.countWaiting(
      {},
      count => {
        setCountWatingComments(count);
      },
      () => { },
    );
  }, []);

  return (
    <div className="layout-nav">
      <div className="layout-nav_section">
        <p className="layout-nav_section-title">
          Contenu
        </p>

        <LayoutNavLink to="/articles">
          <i className="layout-nav_link-icon material-icons-outlined">article</i>
          <span className="layout-nav_link-label">Articles</span>
        </LayoutNavLink>

        <LayoutNavLink to="/categories">
          <i className="layout-nav_link-icon material-icons-outlined">category</i>
          <span className="layout-nav_link-label">Catégories</span>
        </LayoutNavLink>

        <LayoutNavLink to="/dossiers">
          <i className="layout-nav_link-icon material-icons-outlined">find_in_page</i>
          <span className="layout-nav_link-label">Dossiers</span>
        </LayoutNavLink>

        {/* <LayoutNavLink to="/librairie/oeuvres">
          <i className="layout-nav_link-icon material-icons-outlined">book</i>
          <span className="layout-nav_link-label">Œuvres</span>
        </LayoutNavLink> */}

        <LayoutNavLink to="/librairie/livres">
          <i className="layout-nav_link-icon material-icons-outlined">book</i>
          <span className="layout-nav_link-label">Livres</span>
        </LayoutNavLink>

        <LayoutNavLink to="/en-ce-moment">
          <i className="layout-nav_link-icon material-icons-outlined">info</i>
          <span className="layout-nav_link-label">En ce moment</span>
        </LayoutNavLink>

        <LayoutNavLink to="/commentaires">
          <i className="layout-nav_link-icon material-icons-outlined">forum</i>
          <span className="layout-nav_link-label">Commentaires</span>
          <span className="layout-nav_link-badge">{countWatingComments}</span>
        </LayoutNavLink>
      </div>

      <div className="layout-nav_section">
        <p className="layout-nav_section-title">
          Mails
        </p>

        <LayoutNavLink to="/newsletters">
          <i className="layout-nav_link-icon material-icons-outlined">email</i>
          <span className="layout-nav_link-label">Newsletters</span>
        </LayoutNavLink>

        <LayoutNavLink to="/campagnes">
          <i className="layout-nav_link-icon material-icons-outlined">all_inbox</i>
          <span className="layout-nav_link-label">Campagnes</span>
        </LayoutNavLink>
      </div>

      {user.roles.includes('ROLE_ADMIN') && (
        <div className="layout-nav_section">
          <p className="layout-nav_section-title">
            Administration
          </p>

          <LayoutNavLink to="/utilisateurs">
            <i className="layout-nav_link-icon material-icons-outlined">person_outline</i>
            <span className="layout-nav_link-label">Utilisateurs</span>
          </LayoutNavLink>

          <LayoutNavLink to="/publicites">
            <i className="layout-nav_link-icon material-icons-outlined">attach_money</i>
            <span className="layout-nav_link-label">Publicité</span>
          </LayoutNavLink>

          <LayoutNavLink to="/auteurs">
            <i className="layout-nav_link-icon material-icons-outlined">edit</i>
            <span className="layout-nav_link-label">Auteurs</span>
          </LayoutNavLink>

          <LayoutNavLink to="/pages-statiques">
            <i className="layout-nav_link-icon material-icons-outlined">contact_page</i>
            <span className="layout-nav_link-label">Pages statiques</span>
          </LayoutNavLink>

          <LayoutNavLink to="/exports">
            <i className="layout-nav_link-icon material-icons-outlined">import_export</i>
            <span className="layout-nav_link-label">Exports</span>
          </LayoutNavLink>

          <LayoutNavLink to="/parametres">
            <i className="layout-nav_link-icon material-icons-outlined">construction</i>
            <span className="layout-nav_link-label">Paramètres</span>
          </LayoutNavLink>
        </div>
      )}
    </div>
  );
};

export default LayoutNav;
